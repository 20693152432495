.css-1y0gfdp {
  padding-left: 0 !important;
  padding-bottom: 0 !important;
}

.css-18jsosy-MuiPaper-root-MuiCard-root {
  margin-bottom: 0 !important;
  border: none !important;
}

.css-gzlx6l-MuiPaper-root-MuiCard-root {
  box-shadow: none !important;
  border-radius: 0 !important;
  height: 100%;
}

.css-ezl84w {
  height: 100%;
}
.css-13o7eu2 {
  height: 100%;
}

.css-z8lo7x-MuiGrid-root {
  height: 100%;
}
.css-3qs0ce-MuiGrid-root {
  height: calc(100% - 60px);
}

.css-fhmnz5-MuiContainer-root {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.css-r5xg6s {
  padding-bottom: 0 !important;
}

.css-bnwpjm-MuiTableRow-root {
  background-color: #1b8dcd !important;
}
.css-bnwpjm-MuiTableRow-root th {
  color: white !important;
  font-weight: bold;
}
